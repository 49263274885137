@import './theme';
@import 'bootstrap/scss/bootstrap';

html, body, #root {
  height: 100%;
}

label {
  color: $success;
}

.mm-btn-new {
  display: block;
  cursor: pointer;
  color: $success;
  background: rgba($success, .0875);
  text-align: center;
  border-radius: $border-radius;
  transition: $transition-base;

  &:hover {
    background: rgba($success, .15);
    text-decoration: none;
    color: $success;
  }

  &-xl {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bg-tan {
  background: var(--tan);
}

#content {
  height: 100%;

  .content-body {
    padding: $mm-padding-y $mm-padding-x;
  }
}

/**
 * Draggable
 */
.mm-draggable-section {
  background: darken($tan-light, 3%);
  border-radius: $border-radius;
  border: 2px dashed $tan-dark;
}

.mm-draggable-item {
  transition: background 300ms ease-in-out;
}

.mm-drag-item {
  background: $mm-drag-item-bg;

  .modal-header {
    background: $mm-drag-item-bg;
  }
}

/**
 * Modal Overrides
 */
.mm-primary-top .modal-content {
  border-top: $mm-border-width-active-lg solid $primary;
}

.mm-success-top .modal-content {
  border-top: $mm-border-width-active-lg solid $success;
}

.mm-secondary-top .modal-content {
  border-top: $mm-border-width-active-lg solid $secondary;
}

.mm-warning-top .modal-content {
  border-top: $mm-border-width-active-lg solid $warning;
}

.mm-danger-top .modal-content {
  border-top: $mm-border-width-active-lg solid $danger;
}

/**
 * Toastify
 */

.mm-toast {
  padding: .8rem;
}

.Toastify__toast {
  font-family: $font-family-sans-serif;
  border-radius: $border-radius;
}

.Toastify__toast--default {
  background: $primary;
}

.Toastify__toast--success {
  background: $success;
}

.Toastify__toast--warning {
  background: $warning;
}

.Toastify__toast--error {
  background: $danger;
}

/**
 * Bootstrap overrides
 */

.card-header {
  background: $white;
  border-bottom: none;
}

.modal-header {
  padding-top: $mm-padding-y;
  padding-bottom: 0;
}

.modal-footer {
  padding: 1rem $mm-padding-x;
  background: $gray-100;
}

.nav .nav-item .nav-link.active {
  border-bottom: $mm-border-width-active solid $success;
  font-weight: bold;
}

.input-group > .form-control:not(:first-child) {
  border-left: none;
}