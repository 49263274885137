@import '../theme';
@import 'bootstrap/scss/bootstrap';

.cat {
  background: darken($tan-light, 2%);
  margin-bottom: map-get($spacers, 3);
  padding: map-get($spacers, 2);
  border-radius: $border-radius;

  &__header {
    display: flex;
    padding: map-get($spacers, 3) map-get($spacers, 3);
    justify-content: space-between;
    align-items: center;

    & h5, .btn-link {
      color: $dark;
    }
  }

  &__grip {
    margin-right: .8rem;
  }

  &__title {
    text-align: center;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.prod {

  &__btn {
    padding: 1rem 0;
    text-align: center;
    transition: background 500ms;
    border-radius: $border-radius;
    background: $tan;
    cursor: pointer;
    color: $dark;
  }

  &__btn:hover {
    background: darken($tan, 2%);
  }
}