@import '../../theme';
@import 'bootstrap/scss/bootstrap';

$drop-height: 300px;

.drop {

  margin-bottom: map-get($spacers, 1);
  position: relative;

  &__loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: $border-radius;
    background: rgba(#000, .8);
  }

  &__placeholder {
    height: $drop-height;
    border: 2px dashed $tan;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    cursor: pointer;
    transition: background 500ms, border-color 500ms;

    &:hover,
    &--active {
      background: $tan-light;
      border-color: $secondary;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  &__content {
    height: $drop-height;
    width: 100%;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.btns {
  display: flex;
  justify-content: space-between;
}