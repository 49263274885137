@import '../../theme';
@import 'bootstrap/scss/bootstrap';

/**
 * Navbar
 */

@media (min-width: 768px) {
  body {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  body {
    margin-left: 232px;
  }
}

.navbar.fixed-left {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.navbar.fixed-left hr {
  border: 1px solid $tan-light;
  width: 100%;
}

.navbar.fixed-left .navbar-brand {
  display: inline-block;

  .title {
    margin-bottom: 0;
    text-align: left;
    color: $dark;
  }
}

.navbar.fixed-left .navbar-collapse .navbar-nav .nav-link {
  width: 100%;
  color: $dark;
  font-weight: bold;
  font-size: $font-size-lg;
  position: relative;
}

.navbar.fixed-left .navbar-collapse .navbar-nav .nav-item:hover,
.navbar.fixed-left .navbar-collapse .navbar-nav .nav-link:hover,
.navbar.fixed-left .navbar-collapse .navbar-nav .nav-item.active,
.navbar.fixed-left .navbar-collapse .navbar-nav .nav-link.active {
  color: $primary;
  text-decoration: underline;
}

#signout {
  color: $success;
  font-size: $font-size-sm;
  font-weight: normal;
  padding-bottom: .5rem;
}

#version {
  padding-top: 0;
  padding-bottom: 0;
  color: $gray-400;
  font-size: .75rem;

  &:hover {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .navbar.fixed-left {
    bottom: 0;
    width: 232px;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: auto;
    border-radius: 0 $border-radius $border-radius;
  }

  .navbar.fixed-left .navbar-brand {
    width: 100%;
    text-align: center;
    margin-right: 0;
    padding: 2rem 1rem;
  }

  .navbar.fixed-left .navbar-collapse {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .navbar.fixed-left .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item,
  .navbar.fixed-left .navbar-collapse .navbar-nav .nav-link {
    width: 100%;
    padding: .6rem 1rem;
  }

  .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
    top: 0;
  }
}

@media (min-width: 768px) {
  .navbar.fixed-left {
    right: auto;
  }
  .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-left: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    border-right: none;
    vertical-align: baseline;
  }
  .navbar.fixed-left .navbar-nav .nav-item .dropdown-menu {
    left: 100%;
  }
}