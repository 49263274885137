@import '../theme';

$text-margin-bottom: .4rem;

.search {
  min-height: 450px;

  .dropdown-item {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  &__name {
    margin-bottom: $text-margin-bottom;
    font-size: 1.1rem;
  }

  &__name.active {
    color: $white;
  }

  &__desc {
    margin-bottom: $text-margin-bottom;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__price {
    color: $primary;
  }

  .dropdown-item.active &__name,
  .dropdown-item.active &__price {
    color: $white;
  }
}