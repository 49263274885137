@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');

/**
 * App Theme
 */
$tan: #eee4d0;
$tan-light: lighten($tan, 5%);
$tan-dark: #d3c7b2;
$gray-dark: #4e4e56;
$green: #00a69c;
$white: #fff;

$mm-padding-y: 1.5rem;
$mm-padding-x: 1.5rem;
$mm-border-width-active: .25rem;
$mm-border-width-active-lg: $mm-border-width-active + .1rem;
$mm-drag-item-bg: rgba(#fff, .7%);

:root {
  --tan: #eee4d0;
  --tan-light: $tan-light;
  --tan-dark: #d3c7b2;
  --tan-darker: $secondary;
  --gray-dark: #4e4e56;
  --green: #00a69c;
  --font-family-sans-serif: Nunito, sans-serif;
}

/**
 * Bootstrap Theme Overrides
 */

$primary: $green;
$secondary: darken($tan-dark, 30%);
$info: #439dbb;
$success: $green;
$warning: #ffda69;
$danger: #eb5757;
$dark: #4e4e56;

$body-bg: $tan-light;
$body-color: $dark;

$font-family-sans-serif: Nunito, sans-serif;
$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$border-radius: .35rem;
$border-radius-lg: .4rem;
$border-radius-sm: .3rem;

$input-btn-padding-x: .9rem;
$input-btn-padding-y: .65rem;
$input-group-addon-bg: #fff;

$input-border-color: $tan;

$form-group-margin-bottom: 1.2rem;

$font-family-sans-serif: 'Nunito', sans-serif;
$headings-font-weight: 700;
$headings-color: $primary;

$card-border-width: 0;

$modal-inner-padding: $mm-padding-y $mm-padding-x;
$modal-header-padding-x: $mm-padding-x;
$modal-content-border-width: 0;