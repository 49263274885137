@import '../../theme';
@import 'bootstrap/scss/bootstrap';

.mm-overlay {
  position: absolute;
  border-radius: $border-radius;
  top: -$mm-border-width-active-lg;
  left: 0;
  width: 100%;
  height: calc(100% + #{$mm-border-width-active-lg} + 1px);
  z-index: 100;
  background: rgba(0, 0, 0, .9);

  &--show {
    display: block;
  }

  &--hide {
    display: none;
  }

  &__body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mm-content {
  padding: map-get($spacers, 3);
  text-align: center;

  &__title--primary,
  &__desc--primary {
    color: $primary;
  };

  &__title--secondary,
  &__desc--secondary {
    color: $secondary;
  };

  &__title--info,
  &__desc--info {
    color: $info;
  };

  &__title--warning,
  &__desc--warning {
    color: $warning;
  };

  &__title--danger,
  &__desc--danger {
    color: $danger;
  };
}