@import "../theme";
@import 'bootstrap/scss/bootstrap';

.store {
  background: url('../common/images/public-background.png');
  background-size: cover;
  color: $white;
  text-align: center;
  padding: 3.2rem 0;
  position: relative;

  &__badge {
    font-size: 1rem;
    margin-left: map-get($spacers, 2);
  }

  &__overlay {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, .8);
    z-index: 0;
  }

  & h2, address, a {
    text-align: center;
    color: #fff;
    margin-bottom: .2rem;
    position: relative;
  }

  &__web {
    font-weight: bold;
  }
}

.content {
  position: relative;
  width: 100%;

  &__body {
    background: #fff;
    padding: 1rem 1.5rem;
    max-width: 100%;
    margin: 0;
    border-radius: 0;
  }
}

.footer {
  text-align: center;
  padding: .8rem 0 1.5rem;

  &__prefix {
    color: $success;
    font-size: .7rem;
  }

  &__company a {
    font-size: .85rem;
    font-weight: bold;
    color: $dark;
  }

  &__company a:hover {
    color: $dark;
    text-decoration: none;
  }
}

@media (min-width: 992px) {

  .store {
    padding: 3.2rem 1rem 7.2rem;
  }

  .content {
    max-width: 992px;
    margin: auto;

    &__container {
      width: 100%;
      top: -4rem;
      left: 0;
      position: absolute;
      display: block;
    }

    &__body {
      border-radius: $border-radius;
    }
  }
}