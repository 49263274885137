@import '../theme';
@import 'bootstrap/scss/bootstrap';

$margin: .25rem 0;

.section {

  &__title h1, h2, h3, h4, h5 {
    color: $dark;
    margin: $margin;
  }

  &__desc {
    color: $text-muted;
    margin: $margin;
  }

  &__edit {
    margin: $margin;
  }
}