@import '../theme';
@import 'bootstrap/scss/bootstrap';

$prod-thumb-width: 80px;
$prod-detail-height: 300px;
$sticky-z-index: 999;

.menu {

  padding: 0 .375rem;

  &__name {
    text-align: center;
    padding: .25rem 0 0;
    margin-bottom: 0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: $sticky-z-index + 1;
    background-color: $white;
  }

  &__catdivider {
    padding: 1.25rem 0;
  }

  &__catname {
    padding: .25rem 0;
    margin-bottom: 0;
    color: $dark;
    text-align: center;
    position: sticky;
    position: -webkit-sticky;
    top: 35px;
    background-color: $white;
    z-index: $sticky-z-index;
  }

  &__prods {
    padding: map-get($spacers, 3) 0;
  }

  &__prod {
    margin: map-get($spacers, 3) 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  &__prodimg {
    height: $prod-thumb-width;
    width: $prod-thumb-width;
    margin-right: map-get($spacers, 2);

    img {
      height: $prod-thumb-width;
      width: $prod-thumb-width;
      border-radius: $border-radius;
      object-fit: cover;
    }
  }

  &__prodname,
  &__proddesc,
  &__prodprice {
    margin-bottom: map-get($spacers, 1);
  }

  &__prodprice {
    color: $success;
  }
}

/**
 * Modal
 */
.detail {

  padding: 0;
  position: relative;

  &__img {
    height: $prod-detail-height;
    width: 100%;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  &__data {
    padding: map-get($spacers, 4);
  }

  &__title,
  &__desc,
  &__price {
    margin-bottom: map-get($spacers, 2);
  }

  &__title {
    margin-right: map-get($spacers, 4);
  }

  &__price {
    color: $primary;
  }

  &__close {
    text-decoration: none;
    position: absolute;
    top: map-get($spacers, 2);
    right: map-get($spacers, 2);
  }
}

@media (min-width: map-get($grid-breakpoints, 'md')) {

  .menu {
    padding: 0 2.2rem;
  }

}